<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>스케줄 관리</h2>
    </div>

    <div class="box one planner">
      <div>
        <el-select v-model="planner" placeholder="선택" class="searchCondition" @change="getScheduleList" v-if="adminType == 'SUPER'">
          <el-option key="전체" label="전체" value=""> </el-option>
          <el-option v-for="data in plannerList" :key="data._id" :label="data.plannerName" :value="data._id"> </el-option>
        </el-select>
        <router-link to="/admin/schedulemanage/register" class="flr mb8">
          <button class="basic" v-if="planner || adminType != 'PLANNER'">스케줄 등록</button>
        </router-link>
      </div>
      <div class="calendar">
        <vc-calendar class="custom-calendar max-w-full" :attributes="attributes" disable-page-swipe is-expanded>
          <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10 overflow-hidden" @click="handleDate(day)">
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <p v-for="attr in attributes" :key="attr.key" class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1" :class="attr.customData.class">
                  <!-- class : 고객스케줄 - client, 일반스케줄 - planner -->
                  {{ attr.customData.title }}
                </p>
              </div>
            </div>
          </template>
        </vc-calendar>
      </div>
    </div>
    <div class="box one filter">
      <div class="plannerBox">
        <div class="schedule">
          <p class="date">{{ moment(date).format("M월 D일") }}</p>
          <table v-if="scheduleList.length > 0">
            <colgroup>
              <col style="width: 10%" />
              <col style="width: 15%" />
              <col style="width: 15%" />
              <col style="width: 45%" />
              <col style="width: 15%" />
            </colgroup>
            <tr>
              <th>시간</th>
              <th>구분</th>
              <th>스케줄 항목</th>
              <th>스케줄명</th>
              <th>담당</th>
            </tr>

            <tr v-for="(data, i) in scheduleList" :key="i">
              <td>{{ moment(data.date).format("HH:mm") }}</td>
              <td>{{ data.scheduleType == "planner" ? "일반 스케줄" : "고객 스케줄 / " + data.user.username }}</td>
              <td>{{ data.category }}</td>
              <td>
                <router-link :to="{ name: 'scheduleregister', query: { id: data._id } }">{{ data.title }} </router-link>
              </td>
              <td>{{ data.planner.plannerName }}{{ data.planner.plannerType == "DIRECTOR" ? " 디렉터" : " 플래너" }}</td>
            </tr>
          </table>
          <NoData v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchScheduleList, fetchPlannerList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "toymanage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      plannerList: [],
      allScheduleList: [],
      scheduleList: [],
      planner: "",
      date: moment().format("YYYY-MM-DD"),

      attributes: [],

      adminType: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5-2");
  },
  mounted() {
    if (this.adminType == "SUPER") {
      this.getPlannerList();
      this.getScheduleList();
    } else {
      this.planner = localStorage.getItem("plannerId");
      if (this.planner != null) {
        this.getScheduleList();
      }
    }
  },
  beforeMount() {
    this.adminType = localStorage.getItem("adminType");
  },
  methods: {
    handleMove(id) {
      this.$router.push({ name: "plannerregister", query: { id: id } });
    },
    getScheduleList() {
      this.allScheduleList = [];
      this.scheduleList = [];
      this.attributes = [];
      let params = {
        planner: this.planner,
      };
      fetchScheduleList(params).then((res) => {
        if (res.data.status == 200) {
          this.allScheduleList = res.data.data.sort((a, b) => new Date(a.date) - new Date(b.date));
          this.allScheduleList.forEach((item, index) => {
            let title = moment(item.date).format("HH:mm ") + item.planner.plannerName + "(" + item.category + ")";
            this.attributes.push({
              key: index,
              customData: {
                title: title,
                class: item.scheduleType,
                id: item._id,
              },
              dates: item.date,
            });
          });
          this.handleDate();
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    handleDate(day) {
      if (day) {
        this.date = day.id;
        window.scrollBy({ top: 200, left: 0, behavior: "smooth" });
      }
      this.scheduleList = this.allScheduleList.filter((item) => moment(item.date).format("YYYY-MM-DD") == this.date);
    },

    getPlannerList() {
      let params = {
        limit: 10000,
      };
      fetchPlannerList(params).then((res) => {
        if (res.data.status == 200) {
          this.plannerList = res.data.data;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
